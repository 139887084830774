<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="8">
        <label class="title m-r-5">创建时间</label>
        <span
          style="color:#ee7c4b"
          class="m-r-5 text-bold"
          v-show="approval.createTime"
        > {{approval.createTime}}</span>
        <Tag
          color="primary"
          v-if="approval.status===0"
        >审批中</Tag>
        <Tag
          color=" #44bd32"
          v-else-if="approval.status===1"
        >已通过</Tag>
        <Tag
          color="#ef4f4f"
          v-else-if="approval.status===2"
        >未通过</Tag>
        <Tag
          color="default"
          v-else
        >已撤销</Tag>
      </i-col>
      <i-col span="8">
        <label class="title m-r-5">播出日期</label>
        <span>{{approval.publishDay}}</span>
      </i-col>
      <i-col
        span="8"
        class="text-right"
      >
        <Button
          type="primary"
          size="small"
          v-if="approval.status===2"
          @click="handleRestApproval"
        >重新发起审批</Button>
        <Button
          type="warning"
          size="small"
          v-if="approval.status===0"
          @click="handleUndoApproval"
        >撤销审批</Button>
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col
        span="24"
        v-show="approval.description.length"
      >
        <label class="title m-r-5">备注</label>
        <span>{{approval.description}}</span>
      </i-col>
    </Row>
    <Card
      class="m-t-10"
      dis-hover
    >
      <p slot="title">播单列表</p>
      <Table
        stripe
        :columns="programColumn"
        :data="programList"
        size="small"
      ></Table>
    </Card>
    <Card
      class="m-t-10"
      dis-hover
    >
      <p slot="title">审批记录</p>

      <template v-if="nodes.length>0">
        <span class="workplatform-title">审批进度</span>
        <Steps :current="currentStep">
          <Step
            v-for="(node,i) in nodes"
            :key="i"
            :title="node.approvalnodeName"
            :content="node.approverUserName + '['+ node.statusName + ']'"
          >
          </Step>
        </Steps>
      </template>

      <span class=" workplatform-title">已有审批记录</span>
      <i-table
        stripe
        :columns="titles"
        :data="records"
        size="small"
      ></i-table>

      <template v-if="withdrawRecords.length>0">
        <span class=" workplatform-title">撤回审批记录</span>
        <i-table
          stripe
          :columns="withdrawTitles"
          :data="withdrawRecords"
          size="small"
        ></i-table>
      </template>
    </Card>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { eleArrangeMixins } from '../../mixins/index'
import { formatApprovalResult } from '@/utils/tagStatus'

import { getApprovalPrograms, terminateProgramExamine, reStartProgramExamine } from '@/api/inventory/editing'
import { getApprovalSchedule } from '@/api/approval/approvaltask'
export default {
  mixins: [sysMixins, eleArrangeMixins],
  data () {
    return {
      approval: {},
      programList: [],
      programColumn: [
        { title: '资产', key: 'assetName' },
        { title: '站点', key: 'stationName' },
        { title: '资源编号', key: 'resourceCode' },
        {
          title: '起止时间',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleViewDetial(params.row)
                }
              }
            }, '详情')
          }
        }
      ],
      undoDesc: '',
      currentStep: 1,
      nodes: [],
      records: [],
      withdrawRecords: [],
      titles: [
        { title: '审批人', width: 100, key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        {
          title: '审批意见',
          render: (h, data) => {
            let isWarning
            if (data.row.processtype === 10 && data.row.taskSupplementFeature === 1) {
              isWarning = h('tag', { props: { color: 'blue', type: 'border' } }, data.row.taskSupplementFeatureName)
            }

            return h('div', [
              formatApprovalResult(h, data.row.approvalResult, data.row.approvalResultName),
              isWarning
            ])
          }
        },
        { title: '审批备注', key: 'comment' }
      ]
    }
  },
  computed: {
    approvalData () {
      return this.$store.state.electronic.approvalRecordData
    },
    approvalId: {
      get () {
        return this.$store.state.electronic.curApprovalId
      },
      set (val) {
        this.$store.commit('set_cur_approval_id', val)
      }
    }
  },
  created () {
    this.setLinkTagArray({ key: 'electronicApprovalDetail', value: '审批详情', actived: true })
    this.setActivedTagName('electronicApprovalDetail')
  },
  // destroyed () {
  //   this.$store.commit('set_cur_approval_record', {})
  // },
  methods: {
    getProgramData () {
      getApprovalPrograms({ relateId: this.approvalId }).then(res => {
        if (res && !res.errcode) {
          this.programList = res
        } else {
          this.programList = []
        }
      })
    },
    /**
     * 获取审批步骤
     */
    getProcessDetail () {
      var node = getApprovalSchedule({
        processtype: 22,
        relateId: this.approval.id,
        includeHistoryProcessinstance: false
      }).then(data => {
        this.nodes = data
      })
      var record = getApprovalSchedule({
        processtype: 22,
        relateId: this.approval.id,
        includeHistoryProcessinstance: true
      }).then(res => {
        this.records = res.filter(item => item.status && item.status === 1)
        this.withdrawRecords = res.filter(item => item.status && item.status === 2)
      })

      Promise.all([node, record]).then(() => {
        var lastRecord = this.records[this.records.length - 1] || {}
        this.currentStep =
          this.nodes.findIndex(
            node => node.approvalnodeId === lastRecord.approvalnodeId
          ) + 1
      })
    },
    /**
     * 撤销审批
     */
    handleUndoApproval () {
      this.undoDesc = ''
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('div', [
            h('p', {
              class: 'p-b-10'
            }, '确定要撤销当前审批？'),
            h('Input', {
              props: {
                value: this.undoDesc,
                autofocus: true,
                placeholder: '填写备注（非必填）'
              },
              on: {
                input: (val) => {
                  this.undoDesc = val
                }
              }
            })
          ])
        },
        onOk: () => {
          terminateProgramExamine({ relateId: this.approvalId, desc: this.undoDesc }).then(res => {
            if (res && res.errcode === 0) {
              this.$store.dispatch('getApporvalRecordData', {})
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 重新发起审批
     */
    handleRestApproval () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要重新发起审批？',
        onOk: () => {
          reStartProgramExamine({ relateId: this.approvalId }).then(res => {
            if (res && res.errcode === 0) {
              this.$store.dispatch('getApporvalRecordData', {})
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  },
  watch: {
    approvalId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.approval = this.approvalData.find(x => x.id === val) || {}
          this.getProgramData()
          this.getProcessDetail()
        }
      }
    },
    approvalData: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.approval = val.find(x => x.id === this.approvalId) || {}
        }
      }
    }
  }
}
</script>
